import React from 'react'
import Layout from '../components/layout'
import logo from '../profile.jpeg'

const links = [
	{link: 'https://twitter.com/calvinsbrew', text: 'Twitter'},
	{link: 'https://calvinsbrew.com', text: 'Newsletter'},
	{link: 'mailto:hello@calvinkoepke.com', text: 'Contact'}
]

const Home = () => (
	<Layout>
		<div className='column logo-wrap'>
			<h1 className="logo-text">Calvin Koepke</h1>
			<img className="logo" src={logo} alt="Calvin Koepke"/>
		</div>
		<div className='column'>
			<h2>Hey, there!</h2>
			<p style={{
				fontSize: 24
			}}>My name is Calvin, and I'm a full-stack engineer. I focus primarily on creating user-friendly and type-safe <a href="https://cardano.org" target="_blank">Cardano</a> dApps.</p>
			<hr />
			<h4>Current Roles</h4>
			<ul>
				<li>Lead Frontend Engineer <a rel="noreferrer" href="https://sundaeswap.finance" target="_blank">SundaeSwap</a></li>
				<li>Cofounder <a rel="noreferrer" href="https://adahandle.com" target="_blank">ADA Handle</a></li>
				<li>Advisor for <a href="https://koralabs.io" target="_blank">Kora Labs</a></li>
			</ul>
			<hr />
			<h4><a href="mailto:hello@calvinkoepke.com">Hire Me</a></h4>
		</div>
	</Layout>
)

export default Home
